import React from 'react';
import Ingredient from './Ingredient';

const Ingredients = (props) => {
    if(!props.selectedOptions) {
        return <React.Fragment />;
    }

    const isCheckedFunc = (packId) => {
        let arr = [];
        if(props.selectedOptions.hasOwnProperty('options')) {
            arr = props.selectedOptions.options.filter(el => {
                return (el.optId === props.optId && el.packId === packId);
            });
        } else if(props.selectedOptions.hasOwnProperty('children')) {
            arr = props.selectedOptions.children[props.childIndex].customizables.filter(el => {
                return (el.optId === props.optId && el.packId === packId);
            });
        }
        return arr.length > 0;
    };

    const renderIngredients = () => {
        return props.packs.map((pack) => {
            const isChecked = isCheckedFunc(pack.id);

            const onIngredientClickedArg = {
                optId: props.optId,
                packId: pack.id,
                type: props.type
            };

            if(props.hasOwnProperty('childIndex')) {
                onIngredientClickedArg.childIndex = props.childIndex;
            }


            return <Ingredient
                key={ pack.id }
                type={ props.type }
                name={ pack.name }
                priceModif={ pack.price_modifier }
                onIngredientClicked={ () => {
                    props.onIngredientClicked(onIngredientClickedArg);
                } }
                isChecked={ isChecked }
            />;
        }
        );
    };

    return (
        <React.Fragment>
            { renderIngredients() }
        </React.Fragment>
    );
};

export default Ingredients;