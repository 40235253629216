import React from 'react';
import { BtnComponentProps } from '../../types';
import styled from 'styled-components/macro';
import BaseBtn from '../BaseBtn';

const Secondary : React.FC<BtnComponentProps> = ({
    type,
    onClicked,
    children,
    boxShadow,
    rounded,
    className
}) => {
    return (
        <BaseBtnWrpStyled
            type={ type }
            onClicked={ onClicked }
            className={ className }
            boxShadow={ boxShadow }
            rounded={ rounded }
        >
            { children }
        </BaseBtnWrpStyled>
    );
};

export default Secondary;

const btnName = 'secondary';

const BaseBtnWrpStyled = styled(BaseBtn)`
    border: 2px solid ${props => props.theme.button[btnName].colors.hoverBg};
    color: ${props => props.theme.button[btnName].colors.normalText};
    background: ${props => props.theme.button[btnName].colors.normalBg};
    &:hover {
        color: ${props => props.theme.button[btnName].colors.hoverText};
    }
    &::before {
        background: ${props => props.theme.button[btnName].colors.hoverBg};
    }
`;