import React from 'react';
import { BaseBtnContainer } from '@kovzydev/module_commoncomponents';
import Amount from './Amount/Amount';
import Details from './Details/Details';
import { imagePrepath, pageImageNames, getStrHelper } from '@kovzydev/module_helpers';
import styled, { ThemeProvider } from 'styled-components';
import Customizables from './Customizables/Customizables';

import * as Types from '../types';

const Popup : React.FC<Types.PopupContainerType.Popup.Props> = (props) => {

    const {
        popupWrpRef,
        onClosePopup,
        popupBoxRef,
        name,
        desc,
        price,
        discountedPrice,
        amount,
        product_image,
        options,
        selectedOptions,
        showPopup,
        onDecrementAmount,
        onIncrementAmount,
        handleAddToCartCallback,
        editMode,
        general,
        mandatoryCustomizableError
    } = props;

    const imageName = general[pageImageNames.productPopup];
    const bannerImagePath = imageName ? imagePrepath(imageName) : window.THEME_CONFIG.defaultImages.PopupBanner;

    return (
        <ThemeProvider theme={ window.THEME_CONFIG }>
            <Wrp ref={ popupWrpRef }>
                <DarkBg onClick={ onClosePopup } />
                <PopupWrp ref={ popupBoxRef }>
                    <PopupImg style={ { backgroundImage: `url(${bannerImagePath})` } }>
                        <i className='fas fa-times closeIcon' onClick={ onClosePopup } />
                        <h3 className='bannerTitle'>{ getStrHelper('choose_dish') }</h3>
                    </PopupImg>
                    <Details
                        selected
                        name={ name }
                        desc={ desc }
                        price={ price }
                        discountedPrice={ discountedPrice }
                        amount={ amount }
                        product_image={ product_image }
                    />

                    <PopupBottomWrp>
                        { mandatoryCustomizableError && (
                            <Error className='text-danger'>{ mandatoryCustomizableError }</Error>
                        ) }
                        <Customizables
                            options={ options }
                            selectedOptions={ selectedOptions }
                            showPopup={ showPopup }
                        />
                        <Amount
                            onDecrementAmount={ onDecrementAmount }
                            amount={ amount }
                            onIncrementAmount={ onIncrementAmount }
                        />
                    </PopupBottomWrp>

                    <AddToCartBtnWrp>
                        <div className='addToCartBtninnerWrp'>
                            <BaseBtnContainer
                                onClicked={ handleAddToCartCallback }
                                className='font-weight-bold headline-font btn-border-rounded w-100 addToCartBtn'
                                btnType='primary'
                                size='lg'
                            >
                                { editMode
                                    ? getStrHelper('save_changes')
                                    : getStrHelper('add_to_cart')
                                }
                            </BaseBtnContainer>
                        </div>
                    </AddToCartBtnWrp>

                </PopupWrp>
            </Wrp>
        </ThemeProvider>
    );
};


export default Popup;

const Error = styled.p`
    margin-bottom: 15px;
`;

const Wrp = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    width: 100%;
    height: 100%;

    overflow: auto;
`;

const DarkBg = styled.div`
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba(0,0,0,0.5);
`;

const PopupWrp = styled.div`
    width: 90%;
    background: ${props => props.theme.background.colors.primary};
    position: absolute;
    left: 50%;
    top: 10px;
    padding-bottom: 85px;
    margin-bottom: 30px;
    box-sizing: content-box;

    @media(min-width: 550px){
        top: 100px;
        width: 500px;
    }
`;

const PopupImg = styled.div`
    height: 90px;
    width: 100%;
    padding: 20px 30px;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: flex-end;

    &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
    }

    .closeIcon{
        position: absolute;
        top: 20px;
        right: 30px;
        font-size:20px;
        color: white;
        cursor: pointer;
    }

    .bannerTitle{
        color: white;
        position: relative;
    }
`;

const AddToCartBtnWrp = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .addToCartBtninnerWrp{
        width: 100%;
        padding: 15px 40px 20px;

        .addToCartBtn{
            font-size: 1.2rem;
            padding: 15px 20px;
        }
    }
`;

const PopupBottomWrp = styled.div`
    padding: 0px 30px 20px;
    position: relative;
`;