import { Dictionary } from './Dictionary';

export const CallAPI = (Name, Data = {}) => {
    const dict = Dictionary[Name];

    if(!dict) return null;


    return fetch(
        (process.env.REACT_APP_APIManager_HOSTNAME ?? dict.Hostname ?? '') + dict.URL, 
        {
            method: dict.Method,
            body: JSON.stringify(Data),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json; charset=UTF-8'
            },
            credentials: 'include'
        });
}