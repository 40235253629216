import React from 'react';
import s from './Amount.module.css';
import styled from 'styled-components';

const Amount = props => {
    return (
        <Wrp>
            <div className='amntBtn' onClick={ props.onDecrementAmount }>
                <i className='fas fa-minus' />
            </div>
            <div className='amnt'>{ props.amount }</div>
            <div className='amntBtn' onClick={ props.onIncrementAmount }>
                <i className='fas fa-plus' />
            </div>
        </Wrp>
    );
};

export default Amount;

const Wrp = styled.div`
display: flex;
align-items: center;
margin-top: 40px;
padding: 0 10px;
user-select: none;

.amntBtn{
   width: 30px;
   height: 30px;
   position: relative;
   color: ${props => props.theme.button.categorySliderButton.colors.normalText};
   background: ${props => props.theme.button.categorySliderButton.colors.normalBg};
   border-radius: 50%;
   cursor: pointer;
}

.amntBtn i {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   font-size: 12px;
}

.amntBtn:hover{
   background: ${props => props.theme.button.categorySliderButton.colors.hoverBg};
   color: ${props => props.theme.button.categorySliderButton.colors.hoverText};
}

.amnt{
   margin: 0 5px;
   font-size: 16px;
   color: ${props => props.theme.text.colors.primary}
}
`;