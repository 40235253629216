import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import Popup from './Popup';
import * as PopupAC from '../actionCreators/Popup/popupReducerActionCreators';
import * as CartAC from '../actionCreators/Cart/cartActionCreators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PopupContainerType } from '../types';
import { SelectedOptionsType, OptionsType } from '../actionCreators/Popup/popupReducerTypes';
import { Types as ApiTypes } from '@kovzydev/kovzyapi';
import withPopup from './withPopup';
import { getStrHelper } from '@kovzydev/module_helpers';

const PopupContainer : React.FC<PopupContainerType.Props> = ({
    id,
    addCartItem,
    removeCartItem,
    index,
    onClosePopup,
    name,
    desc,
    price,
    discountedPrice,
    amount,
    product_image,
    options,
    selectedOptions,
    showPopup,
    onDecrementAmount,
    onIncrementAmount,
    editMode,
    general
}) => {

    const popupWrpRef = useRef<HTMLDivElement>(null);
    const popupBoxRef = useRef<HTMLDivElement>(null);
    const [mandatoryCustomizableError, setMandatoryCustomizableError] = useState<string>('');

    useEffect(() => {
        initPopup();
    }, []);

    useEffect(() => {
        if(showPopup) {
            // preventing body from scrolling, used in order to not conflict with popup's scroll
            // that overflows the screen
            (document.querySelector('html') as HTMLHtmlElement).style.overflowY = 'hidden';

            showPopupAnimation();
            // this.changeDropdownState(true);
        } else {
            // re-enabling body scroll
            (document.querySelector('html') as HTMLHtmlElement).style.overflowY = '';

            closePopupAnimation();
            setMandatoryCustomizableError('');
        }
    }, [showPopup]);

    // popup SHOW/HIDE animation methods
    const  initPopup = () => {
        // onClosePopup();
        // init styles for popup reveal animation
        const popupWrp = popupWrpRef.current;
        const popupBox = popupBoxRef.current;
        gsap.set(popupWrp, { display: 'none', opacity: 0 });
        gsap.set(popupBox, { opacity: 0, scaleX: 0.8, scaleY: 0.8, x: '-50%' });
        // .
    };

    const showPopupAnimation = () => {
        const popupWrp = popupWrpRef.current;
        const popupBox = popupBoxRef.current;

        // animation wrapper
        gsap.set(popupWrp, { display: 'block' });
        gsap.to(popupWrp, { opacity: 1, duration: 0.2 });

        // animation popupbox
        gsap.to(popupBox, { opacity: 1, scaleX: 1, scaleY: 1, duration: 0.2 });
    };

    const closePopupAnimation = () => {
        const popupWrp = popupWrpRef.current;
        const popupBox = popupBoxRef.current;

        // animation wrapper
        gsap.to(popupWrp, {
            opacity: 0, duration: 0.2, onComplete: () => {
                gsap.set(popupWrp, { display: 'none' });
            }
        });

        // animation popupbox
        gsap.to(popupBox, { opacity: 0, scaleX: 0.8, scaleY: 0.8, duration: 0.2 });
    };

    const onClosePopupHandler = () => {
        // TODO: local state showPopup should be moved into global state
        onClosePopup();
    };

    const countProductTotalPriceSingleProduct : (arg : {
        selectedOptions : SelectedOptionsType,
        options : OptionsType,
        startPrice : number
    }) => number = ({
        selectedOptions,
        options,
        startPrice
    }) => {
        if(selectedOptions?.options){
            return selectedOptions.options.reduce((accum, selectedOption) => {
                if(!options?.singleProdOptions){
                    return accum;
                }

                const packsArr = options?.singleProdOptions?.filter(opt => opt.id === selectedOption.optId)[0].packs;
                const priceModifier = packsArr.filter(pack => pack.id === selectedOption.packId)[0].price_modifier;
                return accum + parseFloat(priceModifier) * 100;

            }, startPrice);
        }
        return startPrice;
    };

    const countProductTotalPriceCollection : (arg : {
        selectedOptions : SelectedOptionsType,
        options : OptionsType,
        price : number
    }) => number = ({
        selectedOptions,
        options,
        price
    }) => {
        if(!selectedOptions?.children?.length){
            return price;
        }

        const totalCollectionPrice = selectedOptions.children.reduce((collectionChildrenTotal, selectedChild) => {
            if(!options?.children){
                return collectionChildrenTotal;
            }

            // get matched child
            const matchedChild = options.children.filter((optionChild) => {
                return optionChild.id === selectedChild.id;
            })[0];
            //

            // get matched customizables, from matchedChild above
            const matchedCustomizables = matchedChild.customizables.filter(custom => {
                return selectedChild.customizables.find((childCustom) => {
                    return childCustom.optId === custom.id;
                });
            });
            //

            // get matched packs, based on matchedCustomizables above
            const matchedPacks : Array<ApiTypes.ProductCustomizables.Pack | null> = [];
            matchedCustomizables.forEach((matchedCustomizable) => {
                return matchedCustomizable.packs.forEach(pack => {
                    let foundPack = null;
                    selectedChild.customizables.forEach((childCustom) => {
                        if(childCustom.packId === pack.id) {
                            foundPack = pack;
                        }
                    });
                    matchedPacks.push(foundPack);
                });
            });
            //

            // calculate packs total price, based on matchedPacks above
            let packsTotal = matchedPacks.reduce((packTotal, pack) => {
                if(pack) {
                    return packTotal + parseFloat(pack.price_modifier) * 100;
                }
                return packTotal;
            }, 0);
            packsTotal = packsTotal / 100;
            //

            return collectionChildrenTotal + packsTotal * 100;
        }, 0);
        return price * 100 + totalCollectionPrice;
    };

    const countProductTotalPrice : (arg : {
        selectedOptions : SelectedOptionsType,
        options : OptionsType,
        price : string
    }) => number = ({
        selectedOptions,
        options,
        price
    }) => {
        let totalPrice = 0;
        if(selectedOptions){
            if(selectedOptions?.hasOwnProperty('options')) {
                totalPrice = countProductTotalPriceSingleProduct({
                    selectedOptions,
                    options,
                    startPrice: parseFloat(price) * 100
                });
    
            } else if(selectedOptions?.hasOwnProperty('children')) {
                totalPrice = countProductTotalPriceCollection({
                    selectedOptions,
                    options,
                    price: parseFloat(price)
                });
            }
        }
        return Math.round(totalPrice) / 100;
    };

    const allMandatoryOptionsSelectedCollection = () => {
        let allSelected = true;
        if(options?.children && options?.children?.length > 0) {
            for(let i = 0; i < options.children.length && allSelected; i++) {
                const customizables = options.children[i].customizables;
                for(let x = 0; x < customizables.length && allSelected; x++) {
                    if(customizables[x]?.mandatory === 1) {
                        // check if this one is selected
                        if(selectedOptions?.children) {
                            const selectedCustomizables = selectedOptions.children[i].customizables;
                            const foundIndex = selectedCustomizables.findIndex((el) => {
                                return el.optId === customizables[x].id;
                            });
                            // if index is not found it means that this mandatory option is not selected
                            if(foundIndex === -1) {
                                allSelected = false;
                            }
                        }
                        // /.
                    }
                }
            }
            if(!allSelected) {
                console.log('Select all mandatory fields');
            }
        }
        return allSelected;
    };

    const allMandatoryOptionsSelectedSingleProd = () => {
        let allSelected = true;
        if(options?.singleProdOptions && options?.singleProdOptions?.length > 0) {
            const customizables = options?.singleProdOptions;
            for(let x = 0; x < customizables.length && allSelected; x++) {
                if(customizables[x]?.mandatory === 1) {
                    // check if this one is selected
                    if(selectedOptions?.options) {
                        const foundIndex = selectedOptions?.options.findIndex((el) => {
                            return el.optId === customizables[x].id;
                        });
                        // if index is not found it means that this mandatory option is not selected
                        if(foundIndex === -1) {
                            allSelected = false;
                        }
                    }
                    // /.
                }
            }
        }
        return allSelected;
    };
    const allMandatoryOptionsSelected = () => {
        if(options?.children) {
            return allMandatoryOptionsSelectedCollection();
        } else {
            return allMandatoryOptionsSelectedSingleProd();
        }
    };

    const addCartItemHandler = () => {
        if(!allMandatoryOptionsSelected()){
            setMandatoryCustomizableError(getStrHelper('popup_mandatory_error'));
        } else {
            if(!editMode) {
                // add cart item
                addCartItem(
                    id,
                    amount,
                    selectedOptions
                );
                onClosePopupHandler();
    
            } else {
                // edit cart item (remove and add again with new options)
                removeCartItem(index)
                    .then(() => {
                        addCartItem(
                            id,
                            amount,
                            selectedOptions
                        );
                        onClosePopupHandler();
                    });
            }
        }
    };

    const totalPrice = countProductTotalPrice({
        selectedOptions: selectedOptions,
        options: options,
        price: price
    }).toString();

    const totalDiscountedPrice = discountedPrice
        ? countProductTotalPrice({
            selectedOptions: selectedOptions,
            options: options,
            price: discountedPrice
        }).toString()
        : null;
        
    return (
        <Popup
            general={ general }

            name={ name }
            desc={ desc }
            product_image={ product_image }
            price={ totalPrice }
            discountedPrice={ totalDiscountedPrice }
            options={ options }
            showPopup={ showPopup }
            selectedOptions={ selectedOptions }
            editMode={ editMode }

            onClosePopup={ onClosePopupHandler }
            handleAddToCartCallback={ addCartItemHandler }
            mandatoryCustomizableError={ mandatoryCustomizableError }

            popupWrpRef={ popupWrpRef }
            popupBoxRef={ popupBoxRef }

            amount={ amount }
            onIncrementAmount={ onIncrementAmount }
            onDecrementAmount={ onDecrementAmount }
        />
    );
};

const mapStateToProps = (state : any) => ({
    id: state.popup.id,
    showPopup: state.popup.showPopup,
    options: state.popup.options,
    selectedOptions: state.popup.selectedOptions,
    amount: state.popup.amount,
    name: state.popup.name,
    desc: state.popup.desc,
    price: state.popup.price,
    discountedPrice: state.popup.discounted_price,
    product_image: state.popup.product_image,
    editMode: state.popup.editMode,
    index: state.popup.index,
    general: state.app.generalData
});

const mapDispatchToProps : any = (dispatch : any) => ({
    onClosePopup: () => dispatch(PopupAC.closePopup()),
    onIncrementAmount: () => dispatch(PopupAC.incrementAmount()),
    onDecrementAmount: () => dispatch(PopupAC.decrementAmount()),
    addCartItem: (id : any, amount : any, selectedOptions : any) => dispatch(CartAC.addCartItem(id, amount, selectedOptions)),
    removeCartItem: (index : number) => dispatch(CartAC.removeCartItem(index))
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPopup
)(
    PopupContainer
);