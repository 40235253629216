import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { BaseBtnContainerProps, BtnComponentProps } from '../types';
// button components
import Secondary from './Secondary/Secondary';
import Primary from './Primary/Primary';
import Danger from './Danger/Danger';
import Slider from './Slider/Slider';
// .



const BaseBtnContainer : React.FC<BaseBtnContainerProps> = ({
    size = '',
    type = 'submit',
    btnType,
    onClicked,
    linkPath,
    children,
    boxShadow = false,
    rounded = false,
    className = ''
}) => {

    const [sizeClass, setSizeClass] = useState('');

    const [BtnComp, setBtnComp] = useState<Record<string, React.FC<BtnComponentProps>>>({
        Component: () => <React.Fragment />
    });

    useEffect(() => {
        setSizeClass(size);
    }, [size]);

    useEffect(() => {
        switch (btnType.toLowerCase()) {
            default:
            case 'secondary':
                setBtnComp({ Component: Secondary });
                break;
            case 'primary':
                setBtnComp({ Component: Primary });
                break;
            case 'danger':
                setBtnComp({ Component: Danger });
                break;
            case 'slider':
                setBtnComp({ Component: Slider });
                break;
        }
    }, [btnType]);

    const btnRender = () => {
        return (
            <BtnComp.Component
                type={ type }
                onClicked={ onClicked }
                sizeClass={ sizeClass }
                boxShadow={ boxShadow }
                rounded={ rounded }
                className={ className }
            >
                { children }
            </BtnComp.Component>
        );
    };
    
    const btnLinkRender = () => {
        if(linkPath){
            return <Link to={ linkPath }>{ btnRender() }</Link>;
        } else {
            return btnRender();
        }
    };

    return (
        <ThemeProvider theme={ THEME_CONFIG }>
            { btnLinkRender() }
        </ThemeProvider>
    );

};

export default BaseBtnContainer;