import React from 'react';
import IngredientsTitle from './Ingredients/IngredientsTitle';
import Ingredients from './Ingredients/Ingredients';
import { Customizables } from '../../types';

const SingleProductCustomizables : React.FC<Customizables.ProductCustomizablesPropsType> = ({
    options,
    showDropdowns,
    dropdownRefs,
    onIngredientClicked,
    selectedOptions,
    onToggleDropdownState
}) => {

    const optionsArray = options?.singleProdOptions;
    if((optionsArray?.length !== showDropdowns.length) || (optionsArray?.length === 0)) {
        return <React.Fragment />;
    } else {
        const optionsJSX = optionsArray.map((opt, i) => {
            return <div key={ opt.id }>
                <IngredientsTitle
                    text={ opt.name }
                    onDropdownTitleClicked={ () => {
                        onToggleDropdownState(i);
                    } }
                    drpodownIsVisible={ showDropdowns[i] }
                    mandatory={ opt.mandatory }
                />

                <div ref={ dropdownRefs[i] }>
                    <Ingredients
                        type={ opt.type }
                        optId={ opt.id }
                        packs={ opt.packs }
                        onIngredientClicked={ onIngredientClicked }
                        options={ optionsArray }
                        selectedOptions={ selectedOptions }
                    />
                </div>

            </div>;

        });
        return (
            <React.Fragment>
                { optionsJSX }
            </React.Fragment>
        );
    }
};

export default SingleProductCustomizables;
