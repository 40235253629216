const dictionary =  {
    getAccessibleModules: {
        URL: '/api/getAccessibleModules',
        Method: 'POST'
    },

    getCategories: {
        URL: '/api/getCategories',
        Method: 'POST'
    },
    getProducts: {
        URL: '/api/getProducts',
        Method: 'POST'
    },
    getProduct: {
        URL: '/api/getProduct',
        Method: 'POST'
    },
    getProductsByCategory: {
        URL: '/api/getProductsByCategory',
        Method: 'POST'
    },
    getFeaturedProducts: {
        URL: '/api/getFeaturedProducts',
        Method: 'POST'
    },
    getBlogPosts: {
        URL: '/api/getBlogPosts',
        Method: 'POST'
    },
    getBlogPost: {
        URL: '/api/getBlogPost',
        Method: 'POST'
    },
    getSliderItems: {
        URL: '/api/getSliderItems',
        Method: 'POST'
    },
    getSpecialOffers: {
        URL: '/api/getSpecialOffers',
        Method: 'POST'
    },
    getCustomizables: {
        URL: '/api/getCustomizables',
        Method: 'POST'
    },

    // Cart
    getCartItems: {
        URL: '/api/cart/getItems',
        Method: 'POST'
    },
    addCartItem: {
        URL: '/api/cart/addItem',
        Method: 'POST'
    },
    removeCartItem: {
        URL: '/api/cart/removeItem',
        Method: 'POST'
    },
    clearCart: {
        URL: '/api/cart/clearItems',
        Method: 'POST'
    },

    // Checkout
    getCheckoutConfigs: {
        URL: '/api/getCheckoutConfigs',
        Method: 'POST'
    },

    getServiceFeeInfo: {
        URL: '/api/getServiceFeeInfo',
        Method: 'POST'
    },

    getAdditionalFeesInfo: {
        URL: '/api/getAdditionalFeesInfo',
        Method: 'POST'
    },

    getPaymentTypes: {
        URL: '/api/checkout/getPaymentTypes',
        Method: 'POST'
    },

    preCheckoutCouponApply: {
        URL: '/api/checkout/preCheckoutCouponApply',
        Method: 'POST'
    },

    sendVerificationCode: {
        URL: '/api/checkout/sendVerificationCode',
        Method: 'POST'
    },
    checkout: {
        URL: '/api/checkout/checkout',
        Method: 'POST'
    },
    qrCheckout: {
        URL: '/api/checkout/qrcheckout',
        Method: 'POST'
    },

    // General Info
    getGeneralInfo: {
        URL: '/api/getGeneralInfo',
        Method: 'POST'
    },
    getPublicIntegrations: {
        URL: '/api/getPublicIntegrations',
        Method: 'POST'
    },

    // Dynamic Pages
    getDynamicPages: {
        URL: '/api/getDynamicPages',
        Method: 'POST'
    },
    getDynamicPage: {
        URL: '/api/getDynamicPage',
        Method: 'POST'
    },

    getDeliveryTypes: {
        URL: '/api/getDeliveryTypes',
        Method: 'POST'
    },

    getFrontendStrings: {
        URL: '/api/getFrontendStrings',
        Method: 'POST'
    },

    getLanguages: {
        URL: '/api/getLanguages',
        Method: 'POST'
    },

    // Career
    getCareerPosts: {
        URL: '/api/career/getPosts',
        Method: 'POST'
    },
    getCareerPost: {
        URL: '/api/career/getPost',
        Method: 'POST'
    },
    submitCareerEntry: {
        URL: '/api/career/submitEntry',
        Method: 'POST'
    },

    // Gallery
    getGalleryItems: {
        URL: '/api/getGalleryItems',
        Method: 'POST'
    },

    // Branches
    getBranches: {
        URL: '/api/getBranches',
        Method: 'POST'
    },
    chooseUserBranch: {
        URL: '/api/chooseUserBranch',
        Method: 'POST'
    },
    getUserBranch: {
        URL: '/api/getUserBranch',
        Method: 'POST'
    }
};

export default dictionary;
