import getApiManager, { Types as ApiTypes } from '@kovzydev/kovzyapi';
import {
    ActionCreatorTypes,
    ProductPopup
} from './popupReducerTypes';



/*
This function generated empty structure for customizables
of the provided children parameter
*/
export const generateEmptySelectedOptionsCollection :
 ActionCreatorTypes.Helpers.GenerateEmptySelectedOptionsCollection.func = (children) => {
     const newArr : ActionCreatorTypes.Helpers.GenerateEmptySelectedOptionsCollection.ReturnType = [];

     children.forEach((el) => {
         if(el?.amount){
             for(let x = 0; x < el.amount; x++) {
                 newArr.push({
                     id: el.id,
                     customizables: []
                 });
             }
         }
     });

     return newArr;
 };
// /.

const transformFetchedCustomizablesForCollection = (customizablesFetchedData : ApiTypes.ProductCustomizables.SingleProduct | ApiTypes.ProductCustomizables.Collection) => {
    const generatedCollectionOptions : typeof customizablesFetchedData['children'] = [];
    customizablesFetchedData?.children?.forEach(child => {
        if(child.amount){
            for(let x = 0; x < child.amount; x++){
                generatedCollectionOptions.push({
                    id: child.id,
                    name: child.name + ` #${x + 1}`,
                    customizables: child.customizables
                });
            }
        }
    });
    return generatedCollectionOptions;
};

const renderPopupHelperHandleCollection : ActionCreatorTypes.Helpers.RenderPopupHelperHandleCollection = ({
    customizablesFetchedData,
    selectedOptions,
    dispatch,
    usedActions
}) => {
    const {
        setOptions,
        setSelectedOptions
    } = usedActions;

    // Set fetched customizables to state
    dispatch(setOptions({
        children: transformFetchedCustomizablesForCollection(customizablesFetchedData) || []
    }));


    if(!selectedOptions && customizablesFetchedData.children) {
        // No selected options -> generating empty structure and setting to state
        dispatch(setSelectedOptions({
            children: generateEmptySelectedOptionsCollection(customizablesFetchedData.children)
        }));
    } else if(selectedOptions?.children) {
        // We have selected options -> set them to state
        dispatch(setSelectedOptions({
            children: selectedOptions.children
        }));
    }
};

export const renderPopupHelper : ActionCreatorTypes.Helpers.RenderPopupHelperType = ({
    prod_id,
    selectedOptions = null,
    amount = 1,
    editMode = false,
    index = null,
    dispatch,
    actionsUsed
}) => {

    const {
        setOptions,
        setSelectedOptions,
        showPopup
    } = actionsUsed;

    const getProductPromise = getApiManager().getProduct({ Productid: prod_id }).promise;
    const getCustomizablesPromise = getApiManager().getCustomizables({ Productid: prod_id }).promise;

    Promise.all([getProductPromise, getCustomizablesPromise])
        .then(res => {
            const productFetchedData = res[0].data;

            // setting product general data
            if(showPopup){
                dispatch(showPopup({
                    id: productFetchedData.id,
                    name: productFetchedData.name,
                    desc: productFetchedData.description,
                    price: productFetchedData.price,
                    discounted_price: productFetchedData.discounted_price?.toString() || null,
                    amount: amount,
                    editMode: editMode,
                    index: index,
                    product_image: productFetchedData.image_name || null
                }));
            }

            /*
            Setting product's customizables
            type = 1 -> this is are customizables of collection
            type = 0 -> this are customizables of singleProduct
            */
            const customizablesFetchedData = res[1].data;
            if(productFetchedData.type === 1 && customizablesFetchedData.children) {
                renderPopupHelperHandleCollection({
                    customizablesFetchedData,
                    selectedOptions,
                    dispatch,
                    usedActions: {
                        setOptions,
                        setSelectedOptions
                    }
                });
            } else if(customizablesFetchedData.customizables) {
                // this is just a product
                dispatch(setOptions({
                    singleProdOptions: customizablesFetchedData.customizables
                }));
                dispatch(setSelectedOptions({
                    options: selectedOptions?.options || []
                }));
            }
        });
};




/*
This function takes cartItem object and transforms it's selected options
into the format that is valid for selectedOptions in our POPUP REDUCER
*/
export const reverseTransformSelectedOptions : ActionCreatorTypes.Helpers.ReverseTransformSelectedOptions = (cartItem) => {
    const optionsObj : ActionCreatorTypes.Helpers.ReverseTransformSelectedReturnVal =
    cartItem?.customizables ? {
        options: []
    } : {
        children: []
    };

    if(cartItem?.customizables && optionsObj.options) {
        // this is a single product's customizables
        cartItem.customizables.forEach((el) => {
            el.packs.forEach((pack) => {
                optionsObj.options.push({
                    optId: el.id,
                    packId: pack.id
                });
            });
        });
    }
    else if(cartItem?.children && optionsObj.children) {
        // this is a collection's customizables
        cartItem.children.forEach((child) => {
            const curChildCustomizablesArr : ProductPopup.selectedOption[] = [];
            child.customizables.forEach((el) => {
                el.packs.forEach((pack) => {
                    curChildCustomizablesArr.push({
                        optId: el.id,
                        packId: pack.id
                    });
                });
            });

            optionsObj.children.push({
                id: child.id,
                amount: 1,
                customizables: curChildCustomizablesArr
            });
        });

    }

    return optionsObj;
};