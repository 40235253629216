import React from 'react';
import { BuyBtnProps } from '../../types';
import { isMexicanaSite } from '@kovzydev/module_helpers';
import styled from 'styled-components/macro';

const BuyBtn : React.FC<BuyBtnProps> = ({
    handleClick
}) => {
    if(!isMexicanaSite()) {

        let clickHandler;
        if(handleClick){
            clickHandler = handleClick;
        }

        return (
            <BuyBtnStyled onClick={ clickHandler }>
                <i className='fas fa-plus' />
            </BuyBtnStyled>
        );
    }
    return null;
};

const BuyBtnStyled = styled.button`
    background: ${props => props.theme.button.circularIconButton.colors.normalBg};
    color: ${props => props.theme.button.circularIconButton.colors.normalText};
    border-radius: 50%;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
        opacity: 0.8;
    }
`;

export default BuyBtn;
