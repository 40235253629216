import React from 'react';
import styled from 'styled-components';

type Props = {
    iconColor : string;
}

const BestsellerIcon : React.FC<Props> = ({
    iconColor = '#ED2024'
}) => {
    return (
        <Wrp>
            <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
                viewBox='0 0 107.83 181.22'
            >
                <style type='text/css'>
                    { `.st0{fill:${iconColor};}` }
                </style>
                <polygon className='st0' points='53.91,127.58 107.55,181.22 107.83,181.22 107.83,0 0,0 0,181.22 0.28,181.22'/>
            </svg>
        </Wrp>
    );
};

export default BestsellerIcon;

const Wrp = styled.div`
    width: 30px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(10px);
    z-index: 1;
`;
