import React from 'react';
import styled from 'styled-components/macro';
import { BaseBtnProps } from '../types';

const BaseBtn : React.FC<BaseBtnProps> = ({
    type,
    onClicked,
    className,
    children,
    boxShadow,
    rounded
}) => {
    return (
        <ButtonStyled
            type={ type }
            className={ className }
            onClick={ onClicked }
            boxShadow={ boxShadow }
            rounded={ rounded }
        >
            { children }
        </ButtonStyled>
    );
};

const boxShadowStyle = 'box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.28);';
const ButtonStyled = styled.button<BaseBtnProps>`
    padding: 5px 10px;
    font-size: 14px;
    position: relative;
    transform: perspective(1px) translateZ(0);
    overflow: hidden;
    line-height: normal;
    transition: all .3s ease;
    cursor: pointer;

    // box shadow
    ${props => props.boxShadow ? boxShadowStyle : ''}
    // border radius-> rounded
    ${props => props.rounded ? 'border-radius: 10px;' : ''}

    &.md{
        padding: 10px 15px;
        font-size: 16px;
        ${props => props.rounded ? 'border-radius: 15px;' : ''}
    }

    &.lg{
        padding: 15px 20px;
        font-size: 17px;
        ${props => props.rounded ? 'border-radius: 20px;' : ''}
    }

    &::before{
        content: "";
        display: block;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -4;

        transform: translateY(100%);

        transition: all .3s ease-in-out;
    }

    &:hover::before, &.active::before{
        transform: translateY(0);
    }
`;

export default BaseBtn;