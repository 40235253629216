import React, { useEffect, useState, useRef, createRef } from 'react';
import SingleProductCustomizables from './SingleProductCustomizables';
import CollectionCustomizables from './CollectionCustomizables';
import { gsap } from 'gsap';
import * as PopupAC from '../../actionCreators/Popup/popupReducerActionCreators';
import { connect } from 'react-redux';
import { PopupContainerType, Customizables } from '../../types';

interface Props {
    options : PopupContainerType.SharedProps['options'],
    selectedOptions : PopupContainerType.SharedProps['selectedOptions'],
    showPopup : PopupContainerType.SharedProps['showPopup'],
    onRemoveIngredient : PopupContainerType.Props['onRemoveIngredient'],
    onAddIngredient : PopupContainerType.Props['onAddIngredient'],
}

const Customizables : React.FC<Props> = ({
    options,
    selectedOptions,
    showPopup,
    onRemoveIngredient,
    onAddIngredient
}) => {
    // state
    const [dropdownRefs, setDropdownRefs] = useState<Customizables.ProductCustomizablesPropsType['dropdownRefs']>([]);
    const [showDropdowns, setShowDropdowns] = useState<Customizables.ProductCustomizablesPropsType['showDropdowns']>([]);
    const [initedDropdowns, setInitedDropdowns] = useState(false);
    // refs
    const prevOptionsRef = useRef<Customizables.ProductCustomizablesPropsType['options']>(null);

    const initDropdowns = () => {
        if(showDropdowns?.length > 0 && !initedDropdowns) {
            /* this is point when options have already synched and rendered and need to be initialized
               using dropdownRefs array and gsap
            */

            // this function inizializes every dropdown for animation using gsap
            dropdownRefs.forEach((dropdownRef) => {
                gsap.set(dropdownRef.current, { height: 0, overflow: 'hidden' });
            });

            // need to change this state val so that this if clause doesn't execute on every update
            setInitedDropdowns(true);
        }
    };

    const handleDropdowns = () => {
        /* this is point when options have already synched and rendered and need to be initialized
           using dropdownRefs array and gsap
        */
        initDropdowns();
        if(prevOptionsRef.current !== options) {
            if(showPopup) {
                // executes when popup is visible
                if(showDropdowns.length === 0) {
                    // executes when local showDropdowns state has not yet been synced with REDUX options and is an empty array
                    if(options) {
                        if(options?.children) {
                            const dropdownsArr : boolean[] = [];
                            if(options?.children){
                                options.children.forEach((child) => {
                                    for(let x = 0; x < child.customizables.length; x++) {
                                        dropdownsArr.push(false);
                                    }
                                });
                                setShowDropdowns(dropdownsArr);
                            }

                        } else {
                            if(options?.singleProdOptions){
                                const newArr = options.singleProdOptions.map(() => false);
                                setShowDropdowns(newArr);
                            }
                        }
                    }
                    // sync dropdownRefs array to store refs for all dropdowns
                    syncDropdownRefs();
                }
            } else {
                // executes when popup closes and options in redux are wiped
                setShowDropdowns([]);
                setInitedDropdowns(false);
            }
        }

    };

    const animateDropdowns = () => {
        dropdownRefs.forEach((dropdownRef, i) => {
            if(dropdownRef?.current){
                if(showDropdowns[i] && dropdownRef.current) {
                    const dropdownScrollHeight = dropdownRef.current.scrollHeight;
                    gsap.to(dropdownRef.current, { height: dropdownScrollHeight, duration: 0.2 });
                } else {
                    gsap.to(dropdownRef.current, { height: 0, duration: 0.2 });
                }
            }
        });
    };

    const syncDropdownRefs = () => {
        if(options) {
            if(options?.children) {
                const newDropdownRefs : typeof dropdownRefs = [];

                options.children.forEach(child => {
                    child.customizables.forEach(() => {
                        newDropdownRefs.push(createRef());
                    });
                });

                setDropdownRefs(newDropdownRefs);

            } else {
                setDropdownRefs(options.singleProdOptions.map(() => {
                    return createRef();
                }));
            }
        }

    };

    const onToggleDropdownState = (index : number) => {
        const newShowDropdowns = showDropdowns.map((el, i) => {
            if(i === index) {
                return !el;
            }
            return el;
        });
        // ;
        setShowDropdowns(newShowDropdowns);

    };

    
    const onIngredientClicked : (arg : Customizables.onIngredientClickedArgType) => void = ({
        optId,
        packId,
        type,
        childIndex = null
    }) => {


        // method checks if this pack of this option is already selected
        const isSelected = () => {
            let res = -1;
            if(selectedOptions?.options) {
                // single product
                res = selectedOptions.options.findIndex((opt) => {
                    return opt.optId === optId && packId === opt.packId;
                });
            } else if(selectedOptions?.children && Number.isInteger(childIndex) && childIndex !== null) {
                // collection
                res = selectedOptions.children[childIndex].customizables.findIndex((opt) => {
                    return opt.optId === optId && packId === opt.packId;
                });
            }
            return res !== -1;
        };
        // /.

        // if childIndex is not NULL then we have a collection
        const ingredientDict : Customizables.onIngredientClickedArgType = {
            type,
            optId,
            packId
        };

        if(Number.isInteger(childIndex)) {
            ingredientDict.childIndex = childIndex;
        }

        // debugger;
        if(isSelected()) {
            onRemoveIngredient(ingredientDict);
        } else {
            onAddIngredient(ingredientDict);
        }
    };

    useEffect(() => {
        handleDropdowns();
    });

    useEffect(() => {
        prevOptionsRef.current = options;
    }, [options]);

    useEffect(() => {
        if(dropdownRefs){
            animateDropdowns();
        }
    }, [showDropdowns, animateDropdowns]);

    if(options && !isNaN(showDropdowns?.length)) {
        if(options.hasOwnProperty('singleProdOptions')) {
            return (
                <SingleProductCustomizables
                    dropdownRefs={ dropdownRefs }
                    options={ options }
                    selectedOptions={ selectedOptions }
                    showDropdowns={ showDropdowns }
                    onToggleDropdownState={ onToggleDropdownState }
                    onIngredientClicked={ onIngredientClicked }
                />
            );
        } else if(options.hasOwnProperty('children')) {
            return (
                <CollectionCustomizables
                    dropdownRefs={ dropdownRefs }
                    options={ options }
                    selectedOptions={ selectedOptions }
                    showDropdowns={ showDropdowns }
                    onToggleDropdownState={ onToggleDropdownState }
                    onIngredientClicked={ onIngredientClicked }
                />
            );
        }
    }

    return null;
};

const mapDispatchToProps = (dispatch : any) => ({
    onAddIngredient: (dict : Customizables.onIngredientClickedArgType) => dispatch(PopupAC.onAddIngredient(dict)),
    onRemoveIngredient: (dict : Customizables.onIngredientClickedArgType) => dispatch(PopupAC.onRemoveIngredient(dict))
});

export default connect(null, mapDispatchToProps)(Customizables);
