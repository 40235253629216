import React from 'react';
import moduleStyles from './Ingredient.module.css';
import { Checkbox, Radio } from '@kovzydev/module_commoncomponents';

import styled from 'styled-components';

const Ingredient = (props) => {

    const priceModifier = props.priceModif[0] !== '-'
        ? `+${props.priceModif}`
        : `-${props.priceModif.slice(1, props.priceModif.length)}`;

    return (
        <Wrp onClick={ props.onIngredientClicked }>
            <div className='d-flex'>
                { props.type === 1
                    ? <Checkbox isChecked={ props.isChecked } name={ props.name } />
                    : <Radio isChecked={ props.isChecked } name={ props.name } />
                }
                <PriceModif className='ml-2'>{ priceModifier }</PriceModif>
            </div>
        </Wrp>
    );
};

export default Ingredient;

const Wrp = styled.div`
   user-select: none;
   padding: 5px 10px;

   &:first-child{
      margin-top: 10px;
  }
`;

const PriceModif = styled.div`
   color: ${props => props.theme.text.colors.special};
`;