import axios, { AxiosPromise, CancelTokenSource } from 'axios';

export default class APIManager {

    // @ts-ignore
    // private defaultLanguageId = 1;

    CallAPI = <T>({
        url,
        method,
        data,
        disallowENVHostname = false
    } : {
        url : string;
        method : 'get' | 'post';
        data ?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
        disallowENVHostname ?: boolean;
    }) : {
        promise : AxiosPromise<T>;
        cancelToken : CancelTokenSource;
    } => {
        const cancelToken = axios.CancelToken.source();

        if(!disallowENVHostname && process.env.REACT_APP_APIManager_HOSTNAME) {
            url = process.env.REACT_APP_APIManager_HOSTNAME + url;
        }

        if(!data.Languageid) {
            // @ts-ignore
            data.Languageid = window?.store?.getState()?.app?.langId;
        }

        const promise = axios({
            url,
            method,
            cancelToken: cancelToken.token,
            withCredentials: true,
            data
        }) as AxiosPromise<T>;

        return {
            promise,
            cancelToken
        };
    };

    // public changeLanguage = (language : number) : void => {
    //     this.defaultLanguageId = language;
    // }
}
