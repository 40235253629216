/* eslint-disable @typescript-eslint/no-explicit-any */
import APIManager from './APIManager';
import Dictionary from './APIDictionary';
import { APIManagerTypes, Types } from './APITypes';

export {
    Types
};

const apiManager = new APIManager();
const proxy = new Proxy(apiManager, {
    get: (apiManager, call) : any => {
        if(apiManager[call]) {
            return apiManager[call];
        }

        const entry = Dictionary?.[call];
        return (attrs : Record<any, any> = {}) : any => {
            const params = entry?.propsPreprocessor?.(attrs) ?? {
                data: attrs
            };

            return apiManager.CallAPI({
                url: entry.URL,
                method: entry.Method,
                ...params
            });
        };
    }
}) as APIManager & APIManagerTypes;

export default () : APIManager & APIManagerTypes => proxy;