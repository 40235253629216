import React from 'react';
import { BaseBtnContainer } from '../index';
import styled from 'styled-components';

type Props = {
    hidePopup : Function | null;
    text : string;
    title : string;
    successBtnCallback : Function | null;
    successBtnText : string;
    cancelBtnCallback : Function | null;
    cancelBtnText : string;
    showButtons : boolean;
}

const CommonPopup : React.FC<Props> = ({
    hidePopup = null,
    text = 'Default Text',
    title = 'Default Title',
    successBtnCallback = null,
    successBtnText = 'Yes',
    cancelBtnCallback = null,
    cancelBtnText = 'No',
    showButtons = false
}) => {
    return (
        <Wrapper>
            <Background onClick={ () => hidePopup ? hidePopup() : null } />
            <PopupWrapper>
                <PopupContent>
                    <CloseBtn onClick={ () => hidePopup ? hidePopup() : null }>
                        <i className='fas fa-times' />
                    </CloseBtn>
                    <h4 className='text-center mb-3'>{ title }</h4>
                    <p className='text-center'>{ text }</p>

                    <div className={ `${!showButtons ? 'd-none' : 'd-flex'} justify-content-center` }>
                        <CustomBtn
                            type='button'
                            btnType='primary'
                            className='mt-3 d-block py-2'
                            size='lg'
                            onClicked={ () => successBtnCallback ? successBtnCallback() : null }
                        >
                            { successBtnText }
                        </CustomBtn>

                        <CustomBtn
                            type='button'
                            btnType='danger'
                            className='mt-3 d-block ml-2 py-2'
                            size='lg'
                            onClicked={ () => cancelBtnCallback ? cancelBtnCallback() : null }
                        >
                            { cancelBtnText }
                        </CustomBtn>
                    </div>
                </PopupContent>
            </PopupWrapper>
        </Wrapper>
    );
};

export default CommonPopup;

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
`;

const Background = styled.div`
    background: rgba(0,0,0,0.8);
    width:100%;
    height:100%;
`;

const PopupWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    
    max-width: 500px;
    width: 100%;
`;

const CloseBtn = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%,-50%);
    width: 50px;
    height: 50px;

    color: ${props => props.theme.text.colors.primary};
    background: ${props => props.theme.background.colors.primary};
    border-radius: 50%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;

    i{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const PopupContent = styled.div`
    position: relative;
    background: ${props => props.theme.background.colors.primary};
    color: ${props => props.theme.text.colors.primary};
    width: 100%;
    padding: 40px 30px;
    margin: auto;
    h3, p{
        text-align: center;
    }
    p{
        color: ${props => props.theme.text.colors.secondary}
    }
`;

const CustomBtn = styled(BaseBtnContainer)`
    min-width: 70px;
`;