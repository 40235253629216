import React, { Suspense, useEffect, useState } from 'react';
import getAPIManager from '@kovzydev/kovzyapi';

class ModulesManager {
    private modules = {};
    private initialized = false;
    private initializeCallbacks = new Set<() => void>();
    private registrationData : {
        'getRoutes' ?: Array<() => React.FC<any>>
        'getNavigations' ?: Array<() => React.FC<any>>
        'getNavigationIcons' ?: Array<() => React.FC<any>>
        'getCartSidebars' ?: Array<() => React.FC<any>>
        'setPopupHasCheckout' ?: Array<() => void>
    } = {
        getRoutes: [],
        getNavigations: [],
        getNavigationIcons: [],
        getCartSidebars: [],
        setPopupHasCheckout: []
    };

    constructor() {
        this.initialize().then(() => {
            this.initialized = true;
            for(const callback of this.initializeCallbacks) {
                callback();
            }
            this.initializeCallbacks.clear();
        }).catch((err) => {
            this.initialized = false;
            console.error(err);
        });
    }

    /*
      This method is used to add special callback to the
      callbacks array, that is executed when all of the modules are initialized
    */
    public registerCallback = (callback : () => void) => {
        if(this.initialized) {
            callback();
            return;
        }

        this.initializeCallbacks.add(callback);
    };

    public unregisterCallback = (callback : () => void) => {
        this.initializeCallbacks.delete(callback);
    };

    /*
    This method loads the list of accessible modules and then stores their contents
    inside of modules property
    */
    private initialize = async () => {
        const request = getAPIManager().getAccessibleModules();
        const response = await request.promise;
        const awaitList : any[] = [];

        // Load accessible modules
        for(const m of response.data) {
            const module = this.loadModule(m);
            if(module) {
                // preparing usable component using React.lazy()
                this.modules[m] = React.lazy(() => module);
                // storing promises inside array to later check if all of modules are loaded
                awaitList.push(module);
            }
        }

        const loadedModules = await Promise.all(awaitList);
        // **
        for(const M of loadedModules) {
            if(M.RegistrationData) {
                Object.keys(M.RegistrationData).forEach(key => {
                    this.registrationData[key].push(M.RegistrationData[key]);
                });
            }
        }
        // **
    };

    /*
    This method loads necessary modules based on the provided key,
    the modules are already installed into the theme and those modules are being loaded
    using import() function.

    This wouldn't work without modules already being installed into the theme.
     */
    private loadModule = (module : string) => {
        switch (module) {
            case 'branches':
                return import('@kovzydev/module_branches');
            case 'featured_products':
                return import('@kovzydev/module_featuredproducts');
            case 'gallery':
                return import('@kovzydev/module_gallery');
            case 'slider':
                return import('@kovzydev/module_slider');
            case 'special_offers':
                return import('@kovzydev/module_specialoffers');
            case 'blog':
                return import('@kovzydev/module_blog');
            case 'dynamic_pages':
                return import('@kovzydev/module_dynamicpages');
            case 'career':
                return import('@kovzydev/module_career');
        }
        return undefined;
    };

    public getModule = (name : string) => {
        return this.modules[name];
    };

    public getRegistrationData = (key : 'getRoutes' | 'getNavigations') => {
        return this.registrationData[key];
    };
}

const moduleManager = new ModulesManager();

export const registerCallback = moduleManager.registerCallback;
export const unregisterCallback = moduleManager.unregisterCallback;
export const getRegistrationData = moduleManager.getRegistrationData;

interface propTypes {
    name : string;
    fallback : any;
    [property : string] : any;
}


/*
This function is used to return packaged component of the module
stored inside "this.modules" via React.lazy().
*/
export const GetModule : React.FC<propTypes> = (props) => {
    const { name, fallback } = props;
    const [Module, setModule] = useState<any>();

    // Simply loads chosen module from this.modules array and
    // stores it inside local state as component "Module"
    const initialized = () => {
        const module = moduleManager.getModule(name);
        if(module) {
            setModule(module);
        }
    };

    useEffect(() => {
        // Registers special callback to run when all of the accessible modules are initialized.
        moduleManager.registerCallback(initialized);

        return () => {
            // cleanup
            moduleManager.unregisterCallback(initialized);
        };
    }, []);

    if(!Module) return fallback;

    return (
        <Suspense
            fallback={ fallback }
        >
            <Module
                { ...props }
                name={ undefined }
                fallback={ undefined }
            />
        </Suspense>
    );
};
