import React from 'react';
import IngredientsTitle from './Ingredients/IngredientsTitle';
import Ingredients from './Ingredients/Ingredients';
import styled from 'styled-components';
import { Customizables } from '../../types';

const CollectionCustomizables : React.FC<Customizables.ProductCustomizablesPropsType> = ({
    options,
    showDropdowns,
    dropdownRefs,
    onIngredientClicked,
    selectedOptions,
    onToggleDropdownState
}) => {

    const optionsArray = options?.children;
    if(!optionsArray?.length || (optionsArray.length === 0)) {
        return <React.Fragment />;
    } else {
        let generatedIndex = -1;
        const optionsJSX = optionsArray.map((child, childIndex) => {
            const optionsMarkup = child.customizables.map((opt) => {
                /*
                        generatedIndex tells us what is the index of the child in the collection,
                        we need this index when we need to independently choose
                        options for different children in the collection.
                        using this index we identify which dropdown is for which child
                     */
                const localGeneratedIndex = ++generatedIndex;
                // /.
                return <div key={ localGeneratedIndex } className={ localGeneratedIndex.toString() }>
                    <IngredientsTitle
                        text={ opt.name }
                        onDropdownTitleClicked={ () => onToggleDropdownState(localGeneratedIndex) }
                        drpodownIsVisible={ showDropdowns[localGeneratedIndex] }
                        mandatory={ opt.mandatory }
                    />

                    <div ref={ dropdownRefs[localGeneratedIndex] }>
                        <Ingredients
                            type={ opt.type }
                            childIndex={ childIndex }
                            optId={ opt.id }
                            packs={ opt.packs }
                            onIngredientClicked={ onIngredientClicked }
                            options={ child.customizables }
                            selectedOptions={ selectedOptions }
                        />
                    </div>
                </div>;

            });

            return (
                <div className='mb-3'>
                    <ChildText>{ child.name }</ChildText>
                    { optionsMarkup }
                </div>
            );
        });

        return (
            <React.Fragment>
                { optionsJSX }
            </React.Fragment>
        );
    }
};

export default CollectionCustomizables;

const ChildText = styled.div`
    font-size: 1.1rem;
    font-weight: bold;
    color: ${props => props.theme.text.colors.primary};
`;
