import React from 'react';
import { connect } from 'react-redux';
import * as PopupAC from '../actionCreators/Popup/popupReducerActionCreators';
import * as Types from '../types';

const withPopup = (Comp : React.FC<any>) : React.FC<Types.WithPopupTypes.Props> => {
    const WrappedComp : React.FC<Types.WithPopupTypes.Props> = (props) => {
        const onRenderPopupHandler : Types.WithPopupTypes.onRenderPopup = (prod_id, selectedOptions) => {
            props.onRenderPopup(prod_id, selectedOptions);
        };

        const onRenderEditCartItemPopupHandler : Types.WithPopupTypes.onRenderEditCartItemPopup = ({
            index,
            amount,
            selectedOptionsFromCart,
            prod_id
        }) => {
            props.onRenderEditCartItemPopup({
                index,
                amount,
                selectedOptionsFromCart,
                prod_id
            });
        };

        return (
            <Comp
                onRenderPopupHandler={ onRenderPopupHandler }
                onEditCartItemHandler={ onRenderEditCartItemPopupHandler }
                { ...props }
            />
        );
    };

    const mapDispatchToProps = (dispatch : any) : Types.WithPopupTypes.MapDispatchToPropsType => ({
        onRenderPopup: (prod_id : number) => dispatch(PopupAC.renderPopup({ prod_id })),
        onRenderEditCartItemPopup: ({
            index,
            amount,
            selectedOptionsFromCart,
            prod_id,
            callback
        }) => {
            return dispatch(PopupAC.editCartItemPopup({
                index,
                amount,
                selectedOptionsFromCart,
                prod_id,
                callback
            }));
        }
    });

    return connect(null, mapDispatchToProps)(WrappedComp);
};

export default withPopup;