import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { imagePrepath } from '@kovzydev/module_helpers';
import styled from 'styled-components/macro';

const Details = props => {
    const renderPrice = () => {
        if(props.hasOwnProperty('discountedPrice') && props.discountedPrice) {
            return (
                <div>
                    <p className='mb-0 mt-2'><del>{ (props.price * props.amount).toFixed(2) } GEL</del></p>
                    <div className='price font-weight-bold'>{ (props.discountedPrice * props.amount).toFixed(2) } GEL</div>
                </div>
            );
        }
        return <p className='price h5 mb-0 mt-2'>{ (props.price * props.amount).toFixed(2) } GEL</p>;
    };

    return (
        <div>
            <Wrp className='d-flex justify-content-between mx-0'>

                <Col xs={ 6 }>
                    <div className='imageHeight'>
                        <div className='innerWrp'>
                            {
                                props.product_image && <img src={ imagePrepath(props.product_image) } className='img' alt='' />
                            }
                        </div>
                    </div>
                </Col>

                <Col xs={ 6 }>
                    <h5 className='title'>{ props.name }</h5>
                    <p className='desc'>{ props.desc }</p>
                    { renderPrice() }
                </Col>
            </Wrp>
        </div>
    );
};

export default Details;

const Wrp = styled(Row)`
   padding: 25px 30px;
   background: ${props => props.theme.background.colors.primary};

   .desc {
      margin-top: 5px;
      color: ${props => props.theme.text.colors.secondary};
   }

   .title{
      color: ${props => props.theme.text.colors.primary};
   }
  
  .price {
      font-weight: bold;
      color: ${props => props.theme.text.colors.special};
  }
  
  .imageHeight {
      position: relative;
  }
  
  .innerWrp{
      width: 100%;
      padding-top: 100%;
  }
  
  .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  
      object-fit: cover;
  }
`;

/*
.wrp {
    padding: 25px 30px;
    margin-bottom: 20px;
    background: #F3F4F4;
}

.desc {
    margin-top: 5px;
    color: #707070;
}

.price {
    font-weight: bold;
}

.imageHeight {
    position: relative;
}

.innerWrp{
    width: 100%;
    padding-top: 100%;
}

.img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
}
*/