import getApiHandler from '@kovzydev/kovzyapi';

// action constants
const GET_CART = 'GET_CART';
const CLEAR_CART = 'CLEAR_CART';

export const ActionConstants = {
    GET_CART,
    CLEAR_CART
};

// action creators
const setCart = (cartData, dispatch) => {
    dispatch({ type: GET_CART, cartData: cartData });
};
 
const getCartHelper = (dispatch) => {
    window.testCart = () => {
        getApiHandler().getCartItems().promise
            .then(res => {
                setCart(res.data, dispatch);
            });
    };
 
    getApiHandler().getCartItems().promise
        .then(res => {
            setCart(res.data, dispatch);
        });
};
 
export const getCart = () => {
    return dispatch => {
        getCartHelper(dispatch);
    };
};
 
export const clearCartUtil = (dispatch) => {
    getApiHandler().clearCart().promise
        .then(() => {
            dispatch({ type: CLEAR_CART });
        });
};
 
export const clearCart = () => {
    return dispatch => {
        clearCartUtil(dispatch);
    };
};
 
const getTransformedItemData = (id, amount, customizablesObj) => {
 
    const itemObj = {
        Item: {
            id: id,
            amount: amount
        }
    };
 
    if(customizablesObj.hasOwnProperty('children')){
       
        const childrenCustomizablesArr = [];
        customizablesObj.children.forEach((child) =>  {
            const curCustomizables = [];
            child.customizables.forEach((curVal) => {
                const itemIndex = curCustomizables.findIndex(el => el.id === curVal.optId);
                if(itemIndex !== -1){
                    curCustomizables[itemIndex].packs.push(curVal.packId);
                } else {
                    curCustomizables.push({
                        id: curVal.optId,
                        packs: [curVal.packId]
                    });
                }
            });
 
            childrenCustomizablesArr.push({
                id: child.id,
                amount: 1,
                customizables: curCustomizables
            });
 
        });
 
       
 
        itemObj.Item.children = childrenCustomizablesArr;
 
    } else {
        // transforms customizables
        const customizablesArr = [];
        customizablesObj.options.forEach((curVal, i) => {
            const itemIndex = customizablesArr.findIndex(el => el.id === curVal.optId);
            if(itemIndex !== -1){
                customizablesArr[itemIndex].packs.push(curVal.packId);
            } else {
                customizablesArr.push({
                    id: curVal.optId,
                    packs: [curVal.packId]
                });
            }
        });
 
        itemObj.Item.customizables = customizablesArr;
    }
 
    // return itemObj object
    return itemObj;
 
};
 
export const addCartItem = (id, amount, customizablesObj) => {
 
    const itemData = getTransformedItemData(id, amount, customizablesObj);
 
    
    return dispatch => {
        getApiHandler().addCartItem(itemData).promise
            .then(res => {
                setCart(res.data, dispatch);
            });
    };
};
 
export const removeCartItem = (index) => {
    return dispatch => {
        return getApiHandler().removeCartItem({ Itemid: index }).promise
            .then(res => {
                //
                setCart(res.data, dispatch);
            });
    };
};
 
export const sendVerifCode = (phone) => {
    return dispatch => {
        return getApiHandler().sendVerificationCode({
            phone_number: phone
        });
    };
};
 
export const checkoutFormSubmit = (dataObj, verifCode, isScheduled = false, marker) => {
    return dispatch => {
        const newObj = {
            verification_code: verifCode,
            firstname: dataObj.firstName,
            lastname: dataObj.lastName,
            address: dataObj.street,
            phone_number: dataObj.phone,
            email: dataObj.email,
            comment: dataObj.comment,
            latitude: marker.x,
            longitude: marker.y,
            payment_type: dataObj.paymentMethod,
            delivery_type: parseInt(dataObj.deliveryMethod)
        };
 
        if(isScheduled){
            newObj.delivery_scheduled = dataObj.deliveryScheduled.toISOString().slice(0, 10) + ` ${dataObj.deliveryTime}:00`;
          
        }
 
        return getApiHandler().checkout(newObj).promise;
    };
};