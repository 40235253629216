import React from 'react';
import { Col } from 'react-bootstrap';
import { ProductProps } from '../../types';
import styled from 'styled-components/macro';
import cx from 'classnames';

// component
import Price from './Price';
import BuyBtn from './BuyBtn';

const Product : React.FC<ProductProps> = ({
    boxShadow,
    discountedPrice,
    price,
    handleClick,
    imageLink,
    name,
    desc,
    sizeMixins = {
        md: 6,
        lg: 4
    },
    className
}) => {
    const productInsides = (
        <ProductInsidesWrp onClick={ handleClick } className={ cx({ boxShadow }, 'd-flex', 'flex-column', 'h-100') }>
            <ImageWrp>
                <Image src={ imageLink } />
            </ImageWrp>
            <ProductInsidesInnerWrp>
                <div>
                    <Name>{ name }</Name>
                    <Desc>{ desc }</Desc>
                </div>
                <BuyBtnWrp>
                    <Price
                        discountedPrice={ discountedPrice }
                        price={ price }
                    />
                    <BuyBtn />
                </BuyBtnWrp>
            </ProductInsidesInnerWrp>
        </ProductInsidesWrp>
    );

    return (
        <Col { ...sizeMixins } className={ `${className}` }>
            { productInsides }
        </Col>
    );


};

export default Product;

const ProductInsidesWrp = styled.div`
    cursor: pointer;
    &.boxShadow{
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    }
`;

const ProductInsidesInnerWrp = styled.div`
    background: ${props => props.theme.background.colors.primary};
    color: ${props => props.theme.text.colors.primary};
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const Name = styled.p`
    font-size: 1.2rem;
    margin-bottom: 10px;
`;

const Desc = styled.p`
    color: ${props => props.theme.text.colors.secondary};
`;

const ImageWrp = styled.div`
    position: relative;
    width: 100%;
    padding-top: 80%;
`;

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
`;

const BuyBtnWrp = styled.div`
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #b6b6b6;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;