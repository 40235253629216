import React from 'react';
import Product from './Product/Product';
import { Row } from 'react-bootstrap';
import { imagePrepath } from '@kovzydev/module_helpers';
import { ProductsProps } from '../types';
import { ThemeProvider } from 'styled-components/macro';

const Products : React.FC<ProductsProps> = ({
    productsArr = [],
    handleClick,
    sizeMixins,
    className = '',
    boxShadow
}) => {
    
    const products = () => {
        return productsArr.map(prod => {
            const addToCartClickedHandler = () => {
                handleClick(prod.id, prod.name, prod.description, prod.price, prod.image_name);
            };

            const price = parseFloat(prod.price).toFixed(2);
            const discountedPrice = prod.discounted_price ? parseFloat(prod.discounted_price).toFixed(2) : undefined;

            return (
                <Product
                    key={ prod.id }
                    name={ prod.name }
                    desc={ prod.description }
                    price={ price }
                    discountedPrice={ discountedPrice }
                    imageLink={ imagePrepath(prod.image_name) }
                    sizeMixins={ sizeMixins }
                    handleClick={ addToCartClickedHandler }
                    className={ className }
                    boxShadow={ boxShadow }
                />);
        });
    };

    return (
        <ThemeProvider theme={ THEME_CONFIG }>
            <Row>{ products() }</Row>
        </ThemeProvider>
    );
};

export default Products;