import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

const IngredientsTitle = props => {
    const renderFlake = () => {
        console.log(props);
        if(props.mandatory === 1){
            return <div className='text-danger font-weight-bold ml-1'>*</div>;
        }
        return null;
    };

    return (
        <DropdownTitleWrp onClick={ props.onDropdownTitleClicked }>
            <Arrow className={ cx(
                [
                    'fas fa-chevron-down', {
                        arrowUp: props.drpodownIsVisible,
                        arrowDown: !props.drpodownIsVisible
                    }]
            ) }
            />
            <div className='d-flex'>
                <DropdownTitle>{ props.text }</DropdownTitle>
                { renderFlake() }
            </div>
        </DropdownTitleWrp>
    );
};

export default IngredientsTitle;

const DropdownTitleWrp = styled.div`
display: flex;
align-items: flex-start;
border-bottom: 1px solid ${props => props.theme.text.colors.primary};
cursor: pointer;
user-select: none;
margin: 7px 15px 0;

&:hover{
   opacity: 0.8;
   transition: all .3s ease;
}
`;

const Arrow = styled.i`
   font-size: 18px;
   margin-right: 10px;
   margin-top: 2px;
   transition: all .3s ease;
   color: ${props => props.theme.text.colors.primary};

   .arrowUp{
      transform: rotateZ(180deg);
   }

   .arrowDown{
      transform: rotateZ(0);
   }
`;

const DropdownTitle = styled.p`
   padding-bottom: 5px;
   font-size: 0.9rem;
   color: ${props => props.theme.text.colors.primary};
`;