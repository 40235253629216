import React from 'react';
import { PriceProps } from '../../types';

const Price : React.FC<PriceProps> = ({
    discountedPrice,
    price
}) => {
    if(discountedPrice) {
        return (
            <div className='d-flex align-items-end'>
                <div className='font-weight-bold'>{ discountedPrice } GEL</div>
                <small className='ml-2'>
                    <del>{ price } GEL</del>
                </small>
            </div>
        );
    }
    return <div className='font-weight-bold'>{ price } GEL</div>;
};

export default Price;
